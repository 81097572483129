// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"

const UserDeviceAuthoriztionList = () => {
  const columns = useMemo(
    () => [
      {
        header: "Sr No",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "User Name",
        accessorKey: "uname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Designation",
        accessorKey: "designation",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Device",
        accessorKey: "device",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Authorize By",
        accessorKey: "authby",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Authorize At",
        accessorKey: "authat",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Auth Valid Till",
        accessorKey: "valid",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Auth Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="/job-details"
                  className="btn btn-sm btn-soft-primary"
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.id}`}
              >
                View
              </UncontrolledTooltip>

              {/* <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleJobClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li> */}

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      srno: "1",
      uname: "Anuja Pawar",
      designation: "Telecaller",
      device: "Android",
      authby: "Pankaj Penkar",
      authat: "22/01/2024 03:13",
      valid: "22/01/2024 03:13",
      status: "Activated",
      actions: 28,
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="User Device Authoriztion" />
        <div className="d-flex align-items-center justify-content-start">
          <div className="flex-shrink-0 m-0 mt-0 mb-0">
            <p>Android Device Cap: 4 Device</p>
          </div>
          <div className="flex-shrink-0 m-5 mt-0 mb-0">
            <p>Android Device Cap: 4 Device</p>
          </div>
        </div>

        <TableContainer
          columns={columns}
          data={data || []}
          isGlobalFilter={true}
          isPagination={true}
          SearchPlaceholder="27 records..."
          pagination="pagination"
          paginationWrapper="dataTables_paginate paging_simple_numbers"
          tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
        />
      </div>
    </div>
  )
}
UserDeviceAuthoriztionList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default UserDeviceAuthoriztionList
