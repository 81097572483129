// src/components/filter.
import PropTypes from "prop-types"
import * as Yup from "yup"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"

import { useFormik } from "formik"
import Select from "react-select"
import React, { useMemo, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"

//Import Breadcrumb
const WoConfirmationPlay = () => {
  //Horizontal form
  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  const columns = useMemo(
    () => [
      {
        header: "W/O Type",
        accessorKey: "wotype",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Play Time (Before)",
        accessorKey: "ptime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Unit",
        accessorKey: "unit",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      wotype: "One Time",
      ptime: "24",
      unit: "Hrs.",
    },
    {
      wotype: "Contract",
      ptime: "18",
      unit: "Hrs.",
    },
    {
      wotype: "Complaint",
      ptime: "12",
      unit: "Hrs.",
    },
  ]

  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Forms"
            breadcrumbItem="Confirmation Play for Work Orders"
          />
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <div className="">
                    <div className="container-fluid">
                      {/* <Breadcrumbs title="Tables" breadcrumbItem="User List" /> */}
                      {/* <CardTitle className="mb-4">
                        Horizontal form Layout
                      </CardTitle> */}

                      <TableContainer
                        columns={columns}
                        data={data || []}
                        paginationWrapper="dataTables_paginate paging_simple_numbers"
                        tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="container-fluid">
                    {/* <CardTitle className="mb-4">
                      Horizontal form Layout
                    </CardTitle> */}
                    <Form onSubmit={horizontalformik.handleSubmit}>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Wrok Order Type
                        </Label>
                        <Col sm={9}>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label
                          htmlFor="horizontal-password-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Confirmation Play
                        </Label>
                        <Col sm={4}>
                          <Input
                            type="password"
                            name="password"
                            className="form-control"
                            id="horizontal-password-Input"
                            autoComplete="off"
                            placeholder="Hrs."
                            value={horizontalformik.values.password}
                            onChange={horizontalformik.handleChange}
                            onBlur={horizontalformik.handleBlur}
                            invalid={
                              horizontalformik.touched.password &&
                              horizontalformik.errors.password
                                ? true
                                : false
                            }
                          />
                          {horizontalformik.errors.password &&
                          horizontalformik.touched.password ? (
                            <FormFeedback type="invalid">
                              {horizontalformik.errors.password}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>

                      <Row className="justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}
WoConfirmationPlay.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default WoConfirmationPlay
