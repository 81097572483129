// src/components/filter.
import React, { useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useFormik } from "formik"
import * as Yup from "yup"

const Callogreport = () => {
  //Grid form
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      city: "",
      state: "",
      zip: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  const columns = useMemo(
    () => [
      {
        header: "Call Date adn Time",
        accessorKey: "calldate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "custname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O No",
        accessorKey: "wono",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O Date And Time",
        accessorKey: "wodateandtime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mobile No.",
        accessorKey: "mobno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Scheduler",
        accessorKey: "scheduler",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Fixed Executive",
        accessorKey: "fixexe",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Call Reason",
        accessorKey: "callres",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "W/O current Status",
        accessorKey: "wostatus",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Postone Reason(if any)",
        accessorKey: "postponres",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      calldate: "1-1-2024 5:45 PM",
      custname: "Avinash Sane",
      wono: "W01233",
      wodateandtime: "1-1-2024 5:45 PM",
      mobno: "7677484848",
      amount: "6000",

      scheduler: "Cockrouch-1R-Gel  Champ AMc",
      fixexe: "10",
      callres: "Semma Rane",
      wostatus: "Singapore",
      postponres: "Singapore",
    },
  ]

  //usestate
  const flatpickrRef = useRef(null)

  const showValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open()
    }
  }

  const closeValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.close()
    }
  }
  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="container-fluid">
      <Row className="page-content">
        <Breadcrumbs
          title="Tables"
          breadcrumbItem="Call Log Report - Call to Customer for Todays W/O from Scheduller"
        />

        <Col xl={12}>
          <div>
            <Card>
              <CardBody>
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col xs={3}>
                      <div className="form-group mb-4">
                        <Label>Form Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div className="form-group mb-4">
                        <Label>To Date</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </div>
                    </Col>

                    <Col xs={3} className="mb-4 mt-4">
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </Col>
                    <CardTitle className="mb-4">
                      Report For the range : 15-07-2024 To 3-2-2024
                    </CardTitle>
                  </Row>
                </Form>
                <Card>
                  <CardBody>
                    <div className="">
                      <CardTitle className="mb-4">Approved Request</CardTitle>

                      <TableContainer
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="27 records..."
                        pagination="pagination"
                        paginationWrapper="dataTables_paginate paging_simple_numbers"
                        tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                      />
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}
Callogreport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Callogreport
