import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Select from "react-select"

const Schedulerassignment = () => {
  //meta title
  document.title = "User Grid | Skote - React Admin & Dashboard Template"

  //   const dispatch = useDispatch()

  //   const ContactsProperties = createSelector(
  //     state => state.contacts,
  //     Contacts => ({
  //       users: Contacts.users,
  //       loading: Contacts.loading,
  //     })
  //   )

  //   const { users, loading } = useSelector(ContactsProperties)
  //   const [isLoading, setLoading] = useState(loading)

  //   useEffect(() => {
  //     if (users && !users.length) {
  //       dispatch(onGetUsers())
  //     }
  //   }, [dispatch, users])
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedMulti, setselectedMulti] = useState(null)
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }
  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Contacts" breadcrumbItem="Scheduler Assignment" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody className="border-bottom">
                  <h5 className="mb-3">Pending Assignment</h5>
                  <div className="hstack gap-2">
                    <p to="#" className="badge bg-primary font-size-15 m-1">
                      Mumbai
                    </p>
                    <p to="#" className="badge bg-primary font-size-15 m-1">
                      Nashik
                    </p>
                    <p to="#" className="badge bg-primary font-size-15 m-1">
                      Pune
                    </p>
                    <p to="#" className="badge bg-primary font-size-15 m-1">
                      Kolhapur
                    </p>
                    <p to="#" className="badge bg-primary font-size-15 m-1">
                      Solapur
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6} sm={6}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Reason Template</CardTitle> */}
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      value="option1"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Seema Kone
                    </label>
                  </div>
                  <Form>
                    <Row className="mb-4">
                      <Col sm={12}>
                        {" "}
                        <div className="mb-3">
                          <label className="control-label">
                            Assigned Location
                          </label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            onChange={() => {
                              handleMulti()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="justify-content-start">
                      <Col sm={9}>
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={6}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Reason Template</CardTitle> */}
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios2"
                      value="option2"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Ramesh Patil
                    </label>
                  </div>
                  <Form>
                    <Row className="mb-4">
                      <Col sm={12}>
                        {" "}
                        <div className="mb-3">
                          <label className="control-label">
                            Assigned Location
                          </label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            onChange={() => {
                              handleMulti()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="justify-content-start">
                      <Col sm={9}>
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} sm={6}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Reason Template</CardTitle> */}
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      id="exampleRadios3"
                      value="option3"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="exampleRadios1"
                    >
                      Vasim Akari
                    </label>
                  </div>
                  <Form>
                    <Row className="mb-4">
                      <Col sm={12}>
                        {" "}
                        <div className="mb-3">
                          <label className="control-label">
                            Assigned Location
                          </label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            onChange={() => {
                              handleMulti()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="justify-content-start">
                      <Col sm={9}>
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Schedulerassignment)
