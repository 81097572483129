// src/components/filter.
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"

const Uploadorchangeorgdata = () => {
  //usestate

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="page-content">
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="Upload Change Org. Logo"
          />

          <Row>
            <Col md={9}>
              <Col md={{ span: 1, offset: 4 }}>
                <Card>
                  <CardBody className="">
                    {/* <h5 className="fw-semibold text-center">
                      Approve Location Request
                    </h5> */}

                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className=" ">
                            <th scope="">Company Name</th>
                            <td scope="w-100">
                              <Input className="select2-selection" />
                            </td>
                          </tr>
                          <tr className=" ">
                            <th scope="">Logo File</th>
                            <td scope="w-100">
                              <Input className="select2-selection" />
                            </td>
                          </tr>
                          {/* <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr>

                          <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr>

                          <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr>

                          <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr> */}

                          <tr className=" ">
                            <th scope="row">
                              {" "}
                              <button className="btn btn-soft-primary w-100">
                                Submit
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Uploadorchangeorgdata.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Uploadorchangeorgdata
