// src/components/filter.
import React, { useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"

const Enquirycalllogreport = () => {
  //Grid form
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      city: "",
      state: "",
      zip: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  const columns = useMemo(
    () => [
      {
        header: "Date Time",
        accessorKey: "dttime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mobile No.",
        accessorKey: "mobileno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "custname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Verify At",
        accessorKey: "verat",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Verify TAt",
        accessorKey: "vertat",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Verifer",
        accessorKey: "verifer",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecaller Call",
        accessorKey: "telcall",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecaller TAT",
        accessorKey: "teltat",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecaller",
        accessorKey: "telcaller",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Product",
        accessorKey: "Product",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "location",
        accessorKey: "location",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      dttime: "1-1-2024 5:4 AM",
      mobileno: "878888888",
      custname: "Dinesh Chole",
      verat: "9:08 AM",
      vertat: "0.08 Hrs",

      verifer: "Santosh patil",
      telcall: "09.98 AM",
      teltat: "0.8 Hrs",
      telcaller: "Anuja Pawar",
      Product: "Product A",
      location: "Varje",

      status: "W/O Placed",
    },
  ]

  //usestate
  const flatpickrRef = useRef(null)

  const showValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open()
    }
  }

  const closeValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.close()
    }
  }
  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row>
          <Col xl={12}>
            <div className="page-content">
              <Breadcrumbs
                title="Forms"
                breadcrumbItem="Enquiry Call Log Report"
              />

              <Card>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      {" "}
                      {/* <Col lg="2">
                        <div className="mb-3">
                          <Label>Initials</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>Form Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>To Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <FormGroup className="mb-3">
                          <Label>Form Time</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select time"
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup className="mb-3">
                          <Label>To Time</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="Select time"
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i",
                              }}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <button type="submit" className="btn btn-primary w-md">
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>

        <div className="">
          <CardTitle className="mb-4">
            {" "}
            Report of Employee Name for the date range: 1-1-2024 to 2-2-2024{" "}
          </CardTitle>

          <TableContainer
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="27 records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
          />
        </div>
      </div>
    </React.Fragment>
  )
}
Enquirycalllogreport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Enquirycalllogreport
