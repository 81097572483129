// src/components/filter.
import React, { useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link } from "react-router-dom"

const Reportmanualchallanworkorders = () => {
  const validation = useFormik({
    initialValues: {
      fullnameInput: "",
      emailInput: "",
      phoneNumberInput: "",
      uploadResume: "",
      messageInput: "",
    },
    validationSchema: Yup.object({
      fullnameInput: Yup.string().required("Please Enter Your Name"),
      emailInput: Yup.string().required("Please Enter Your Email"),
      phoneNumberInput: Yup.string().required("Please Enter Your Number"),
      uploadResume: Yup.string().required("Add Your Resume"),
      messageInput: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: values => {
      setModal()
      validation.resetForm()
    },
  })
  const [modal, setModal] = useState(false)
  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }
  const [modal1, setModal1] = useState(false)
  const toggle1 = () => {
    if (modal1) {
      setModal1(false)
      setJob(null)
    } else {
      setModal1(true)
    }
  }
  //Grid form
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      city: "",
      state: "",
      zip: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  const Approved = useMemo(
    () => [
      {
        header: "Request Date adn Time",
        accessorKey: "enqdate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "custname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O No",
        accessorKey: "wono",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O Date And Time",
        accessorKey: "wodateandtime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mobile No.",
        accessorKey: "mobno",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Product",
        accessorKey: "product",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Qty",
        accessorKey: "qty",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecaller",
        accessorKey: "telecaller",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (
          <Link
            to="#approvedreq"
            onClick={() => setModal(true)}
            className="btn btn-primary me-1 font-size-10"
          >
            Action
          </Link>
        ),
      },
    ],
    []
  )

  const Approveddata = [
    {
      enqdate: "1-1-2024 5:45 PM",
      custname: "Avinash Sane",
      wono: "W01233",
      wodateandtime: "1-1-2024 5:45 PM",
      mobno: "7677484848",
      product: "Cockrouch-1R-Gel  Champ AMc",
      qty: "10",
      amount: "6000",
      telecaller: "Semma Rane",
      action: "Singapore",
    },
  ]
  const Rejected = useMemo(
    () => [
      {
        header: "Request Date adn Time",
        accessorKey: "enqdate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "custname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O No",
        accessorKey: "wono",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O Date And Time",
        accessorKey: "wodateandtime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mobile No.",
        accessorKey: "mobno",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Product",
        accessorKey: "product",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Qty",
        accessorKey: "qty",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecaller",
        accessorKey: "telecaller",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (
          <Link
            to="#rejreq"
            onClick={() => setModal1(true)}
            className="btn btn-primary me-1 font-size-10"
          >
            Action
          </Link>
        ),
      },
    ],
    []
  )

  const Rejecteddata = [
    {
      enqdate: "1-1-2024 5:45 PM",
      custname: "Avinash Sane",
      wono: "W01233",
      wodateandtime: "1-1-2024 5:45 PM",
      mobno: "7677484848",
      product: "Cockrouch-1R-Gel  Champ AMc",
      qty: "10",
      amount: "6000",
      telecaller: "Semma Rane",
      action: "Singapore",
    },
  ]

  //usestate
  const flatpickrRef = useRef(null)

  const showValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open()
    }
  }

  const closeValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.close()
    }
  }
  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row className="page-content">
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="Report - Manual Challan Work Orders Approvals Request W/O From Telecallers"
          />

          <Col xl={12}>
            <div>
              <Card>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>Form Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>To Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>

                      <Col xs={3} className="mb-4 mt-4">
                        <button type="submit" className="btn btn-primary w-md">
                          Submit
                        </button>
                      </Col>
                      <CardTitle className="mb-4">
                        Report For the range : 15-07-2024 To 3-2-2024
                      </CardTitle>
                    </Row>
                  </Form>
                  <Card>
                    <CardBody>
                      <div className="">
                        <CardTitle className="mb-4">Approved Request</CardTitle>

                        <TableContainer
                          columns={Approved}
                          data={Approveddata || []}
                          isGlobalFilter={true}
                          isPagination={true}
                          SearchPlaceholder="27 records..."
                          pagination="pagination"
                          paginationWrapper="dataTables_paginate paging_simple_numbers"
                          tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        />
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="">
                        <CardTitle className="mb-4">Rejected Request</CardTitle>

                        <TableContainer
                          columns={Rejected}
                          data={Rejecteddata || []}
                          isGlobalFilter={true}
                          isPagination={true}
                          SearchPlaceholder="27 records..."
                          pagination="pagination"
                          paginationWrapper="dataTables_paginate paging_simple_numbers"
                          tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
        <Modal
          isOpen={modal}
          toggle={() => {
            setModal()
          }}
          id="approvedreq"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModal()}
              id="applyJobsLabel"
              className="modal-header"
            >
              Approved Request Change To Rejectes
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={validation.handleSubmit} autoComplete="off">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="emailInput">Rejected Request</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="fullnameInput">Admin Remark</Label>
                      <Input
                        type="text"
                        id="fullnameInput"
                        name="fullnameInput"
                        placeholder=""
                        value={validation.values.fullnameInput}
                        onChange={validation.handleChange}
                      />
                      {validation.touched.fullnameInput &&
                      validation.errors.fullnameInput ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.fullnameInput}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <Col lg={12}>
                      <div className="text-start">
                        <button type="submit" className="btn btn-success me-1">
                          Submit
                          <i className="bx bx-send align-middle"></i>
                        </button>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={modal1}
          toggle={() => {
            setModal1()
          }}
          id="rejreq"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModal1()}
              id="rejreq"
              className="modal-header"
            >
              Reject Request Change To Approved
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={validation.handleSubmit} autoComplete="off">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="emailInput">Approved Request</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="fullnameInput">Admin Remark</Label>
                      <Input
                        type="text"
                        id="fullnameInput"
                        name="fullnameInput"
                        placeholder=""
                        value={validation.values.fullnameInput}
                        onChange={validation.handleChange}
                      />
                      {validation.touched.fullnameInput &&
                      validation.errors.fullnameInput ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.fullnameInput}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <Col lg={12}>
                      <div className="text-start">
                        <button type="submit" className="btn btn-success me-1">
                          Submit
                          <i className="bx bx-send align-middle"></i>
                        </button>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
Reportmanualchallanworkorders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Reportmanualchallanworkorders
