// src/components/filter.
import React, { useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useFormik } from "formik"
import * as Yup from "yup"

const Lostreport = () => {
  //Grid form
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      city: "",
      state: "",
      zip: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  const columns = useMemo(
    () => [
      {
        header: "Enq Date",
        accessorKey: "enqdate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Lost Date",
        accessorKey: "lostdate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "W/O Id",
        accessorKey: "woid",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Customer Name",
        accessorKey: "custname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "No. Of follow-ups Done",
        accessorKey: "folloupsdone",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Product",
        accessorKey: "product",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Filed Executive",
        accessorKey: "executive",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Lost Reason",
        accessorKey: "lostreason",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Lost Remark",
        accessorKey: "lostremark",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecaller",
        accessorKey: "telecaller",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      enqdate: "1-1-2014",
      lostdate: "3-2-2024",
      woid: "W028",
      custname: "Neha Patil",
      folloupsdone: "4",
      product: "Produt A",

      amount: "5000",

      executive: "Som Sonavanen",

      lostreason: "Expensive",

      lostremark: "Asking For Rs. 1000",

      telecaller: "Anuja Pawar",
    },
  ]

  //usestate
  const flatpickrRef = useRef(null)

  const showValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open()
    }
  }

  const closeValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.close()
    }
  }
  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row className="page-content">
          <Col xl={12}>
            <div>
              <Card>
                <CardBody>
                  <Breadcrumbs title="Tables" breadcrumbItem="Lost Report" />

                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>From Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>To Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>

                      <Col xs={3} className="mb-4 mt-4">
                        <button type="submit" className="btn btn-primary w-md">
                          Submit
                        </button>
                      </Col>
                    </Row>
                  </Form>
                  <div className="">
                    <CardTitle className="mb-4">
                      Report of date range : 01-01-2024 to 25-01-2024
                    </CardTitle>
                    <TableContainer
                      columns={columns}
                      data={data || []}
                      isGlobalFilter={true}
                      isPagination={true}
                      SearchPlaceholder="27 records..."
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers"
                      tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
Lostreport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Lostreport
