// src/components/filter.
import React, { useMemo, useRef, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const CreateorEditProduct = props => {
  //meta title
  document.title = "Form Layouts | Skote - React Admin & Dashboard Template"

  //Grid form
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      city: "",
      state: "",
      zip: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  const [selectedMulti, setselectedMulti] = useState(null)

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }

  document.title = "Data Tables | Skote - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Create or Edit Product" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Create or Edit User</CardTitle> */}

                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg="4">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                        <div className="mb-3">
                          <Label>Subcategory</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Product
                          </Label>
                          <Input
                            type="text"
                            name="product"
                            className="form-control"
                            id="formrow-firstname-Input"
                            // placeholder="Enter Your First Name"
                            value={formik.values.Product}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.Product && formik.errors.Product
                                ? true
                                : false
                            }
                          />
                          {formik.errors.Product && formik.touched.Product ? (
                            <FormFeedback type="invalid">
                              {formik.errors.Product}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Price</Label>
                          <Input
                            type="text"
                            name="price"
                            className="form-control"
                            id="formrow-firstname-Input"
                            // placeholder="Enter Your First Name"
                            value={formik.values.Price}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.Price && formik.errors.Price
                                ? true
                                : false
                            }
                          />
                          {formik.errors.Price && formik.touched.Price ? (
                            <FormFeedback type="invalid">
                              {formik.errors.Prices}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Min. Price
                          </Label>
                          <Input
                            type="text"
                            name="minprice"
                            className="form-control"
                            id="formrow-firstname-Input"
                            // placeholder="Enter Your MIddle Name"
                            value={formik.values.minprice}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.minprice && formik.errors.minprice
                                ? true
                                : false
                            }
                          />
                          {formik.errors.minprice && formik.touched.minprice ? (
                            <FormFeedback type="invalid">
                              {formik.errors.minprice}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Price Delay
                          </Label>
                          <Input
                            type="text"
                            name="Pricedelay"
                            className="form-control"
                            id="formrow-firstname-Input"
                            // placeholder="Enter Your Last Name"
                            value={formik.values.Pricedelay}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.Pricedelay &&
                              formik.errors.Pricedelay
                                ? true
                                : false
                            }
                          />
                          {formik.errors.Pricedelay &&
                          formik.touched.Pricedelay ? (
                            <FormFeedback type="invalid">
                              {formik.errors.Pricedelay}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Guarantee</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label>Executions</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Intervals
                          </Label>
                          <Input
                            type="text"
                            name="alternateno"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Interval In Days"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                          {/* <Label htmlFor="formrow-firstname-Input">Days</Label> */}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6}>
                        <div className="mb-3">
                          <label className="control-label">Telecallers</label>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            onChange={() => {
                              handleMulti()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default CreateorEditProduct
