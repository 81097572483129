// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

const AuthPendingReq = () => {
  const columns = useMemo(
    () => [
      {
        header: "Sr No",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "User Name",
        accessorKey: "uname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Designation",
        accessorKey: "designation",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Device",
        accessorKey: "device",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Request Timestamp",
        accessorKey: "reqtime",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Manager",
        accessorKey: "manager",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="#editdata"
                  className="btn btn-sm btn-soft-primary"
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.id}`}
              >
                View
              </UncontrolledTooltip>
            </ul>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      srno: "1",
      uname: "Anuja Pawar",
      designation: "Telecaller",
      device: "Android",
      reqtime: "22/01/2024 03:13",
      manager: "Devish Shaha",
      actions: 28,
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="page-content">
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="Authorization Pending Request"
          />
          <div className="d-flex align-items-center justify-content-start">
            <div className="flex-shrink-0 m-0 mt-0 mb-0">
              <p>Android Device Cap: 4 Device</p>
            </div>
            <div className="flex-shrink-0 m-5 mt-0 mb-0">
              <p>Android Device Cap: 4 Device</p>
            </div>
          </div>
          <TableContainer
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="27 records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
          />
        </div>

        <Row id="editdata">
          {" "}
          <Col md={12}>
            <Card>
              <CardBody>
                <h5 className="fw-semibold">Overview</h5>

                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="col">User Name:</th>
                        <td scope="col">Magento Developer</td>
                        <th scope="col">Device Type:</th>
                        <td scope="col">Magento Developer</td>
                        <th scope="col">Authrization Date</th>
                        <td scope="col">Magento Developer</td>
                      </tr>
                      <tr>
                        <th scope="row">Designation:</th>
                        <td>0-2 Years</td>
                        <th scope="row">OS Details:</th>
                        <td>0-2 Years</td>
                        <th scope="row">Request Timestamp:</th>
                        <td>0-2 Years</td>
                      </tr>
                      <tr>
                        <th scope="row">Device Info</th>
                        <td>12</td>
                        <th scope="row">Device Langitude</th>
                        <td>12</td>
                        <th scope="row">
                          {" "}
                          <button className="btn btn-soft-primary w-100">
                            Uthorized Device
                          </button>
                        </th>
                        {/* <td>12</td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}
AuthPendingReq.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AuthPendingReq
