import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"
import { Rating } from "react-simple-star-rating"
//import images
// import wechat from "../../../assets/images/companies/wechat.svg"

const Workorderfeedback = () => {
  const [textareabadge, settextareabadge] = useState(0)
  function textareachange(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }
  return (
    <React.Fragment>
      <Row md={2}>
        <Col md={{ span: 1, offset: 3 }} className="page-content">
          <Card>
            <CardBody className="border-bottom">
              <div className="d-flex">
                <div className="flex-grow-1 ms-3">
                  <h5 className="fw-semibold text-center">
                    Customer Feedback{" "}
                  </h5>
                </div>
              </div>
            </CardBody>
            <CardBody className="ms-3">
              <h5 className="fw-semibold mb-3">Overall Service Questions</h5>

              <label className="text-muted mb-3 p-3 pt-0">
                कंपनीची एकूण सेवा कशी होती ?
              </label>
              <Rating
                size={25}
                initialValue={4}
                readonly
                onClick={function noRefCheck() {}}
              />
              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="customRadioInline1"
                    className="form-check-input"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline1"
                  >
                    सर्वोत्तम
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    उत्तम
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    चांगली
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    समाधानी नाही
                  </label>
                </div>
                &nbsp;
                <Row>
                  <Col md={6} className="form-check mt-3">
                    <Input
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachange(e)
                      }}
                      maxLength="225"
                      rows="2"
                      placeholder="Descriptive Answer"
                    />
                    {textareabadge ? (
                      <span className="badgecount badge bg-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null}
                  </Col>
                </Row>
              </div>
              <label className="text-muted mb-3 p-3 pt-0">
                कोणत्याही सुधारणांची गरज आहे का ?
              </label>
              <Rating
                size={25}
                initialValue={4}
                readonly
                onClick={function noRefCheck() {}}
              />
              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      टेलिकॉलर कम्युनिकेशन
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      वर्क ऑर्डरची अंमलबजावणी
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      फील्ड क्झिक्युटिव्ह वर्तन
                    </label>
                  </div>
                </div>
                &nbsp;
                <Row>
                  <Col md={6} className="form-check mt-3">
                    <Input
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachange(e)
                      }}
                      maxLength="225"
                      rows="2"
                      placeholder="Descriptive Answer"
                    />
                    {textareabadge ? (
                      <span className="badgecount badge bg-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardBody className="ms-3">
              <h5 className="fw-semibold mb-3">Filed Executive Questions</h5>
              <Label className="text-muted mb-3 p-3 pt-0">
                फील्ड एक्झिक्युटिव्ह योग्य गणवेशात होता का ?
              </Label>
              <Rating
                size={25}
                initialValue={4}
                readonly
                onClick={function noRefCheck() {}}
              />
              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="customRadioInline1"
                    className="form-check-input"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline1"
                  >
                    होय
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    नाही
                  </label>
                </div>
                &nbsp; &nbsp;
                <Row>
                  <Col md={6} className="form-check mt-3">
                    <Input
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachange(e)
                      }}
                      maxLength="225"
                      rows="2"
                      placeholder="Descriptive Answer"
                    />
                    {textareabadge ? (
                      <span className="badgecount badge bg-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null}
                  </Col>
                </Row>
              </div>
              <Label className="text-muted mb-3 p-3 pt-0">
                फील्ड एक्झिक्युटिव्हचे वर्तन कसे होते ?
              </Label>
              <Rating
                size={25}
                initialValue={4}
                readonly
                onClick={function noRefCheck() {}}
              />
              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      चांगले
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      सहकार्य करण्यास तयार
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      सुधारणांची गरज आहे
                    </label>
                  </div>
                </div>
                &nbsp;
                <Row>
                  <Col md={6} className="form-check mt-3">
                    <Input
                      type="textarea"
                      id="textarea"
                      onChange={e => {
                        textareachange(e)
                      }}
                      maxLength="225"
                      rows="2"
                      placeholder="Descriptive Answer"
                    />
                    {textareabadge ? (
                      <span className="badgecount badge bg-success">
                        {" "}
                        {textcount} / 225{" "}
                      </span>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Workorderfeedback
