// src/components/filter.
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Form,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { PatternFormat } from "react-number-format"
const LocationApprovalReq = () => {
  // validation
  const validation = useFormik({
    initialValues: {
      fullnameInput: "",
      emailInput: "",
      phoneNumberInput: "",
      uploadResume: "",
      messageInput: "",
    },
    validationSchema: Yup.object({
      fullnameInput: Yup.string().required("Please Enter Your Name"),
      emailInput: Yup.string().required("Please Enter Your Email"),
      phoneNumberInput: Yup.string().required("Please Enter Your Number"),
      uploadResume: Yup.string().required("Add Your Resume"),
      messageInput: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: values => {
      setModal()
      validation.resetForm()
    },
  })
  const columns = useMemo(
    () => [
      {
        header: "Sr No",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Sublocation",
        accessorKey: "subloc",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Location Name",
        accessorKey: "locname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "City",
        accessorKey: "city",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Sent By",
        accessorKey: "sentby",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Request Time",
        accessorKey: "reqtime",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="/job-details"
                  className="btn btn-sm btn-soft-primary"
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleJobClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      srno: "1",
      subloc: "Datta Nagar",
      locname: "Ambegaon BK",
      city: "Pune",
      sentby: "Anuja Pawar",
      reqtime: "20/05/2023 09:34 PM",

      actions: 28,
    },
  ]

  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  const [modal, setModal] = useState(false)
  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  const [modal1, setModal1] = useState(false)
  const toggle1 = () => {
    if (modal) {
      setModal1(false)
      // setJob(null)
    } else {
      setModal1(true)
    }
  }

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="page-content">
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="Location Approval Reqest From Telecaller"
          />

          <div className="d-flex align-items-center justify-content-end">
            <div className="flex-shrink-0">
              <Link
                to="#approvelocation"
                onClick={() => setModal(true)}
                className="btn btn-primary me-1"
              >
                Approve Location Request
              </Link>
            </div>
            <div className="flex-shrink-0">
              <Link
                to="#craetenewlocation"
                onClick={() => setModal1(true)}
                className="btn btn-primary me-1"
              >
                Create New Location
              </Link>
            </div>
          </div>
          <TableContainer
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="27 records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
          />
        </div>

        {/* <Row>
          {" "}
          <Col md={6}>
            <Card>
              <CardBody className="">
                <h5 className="fw-semibold text-center">
                  Approve Location Request
                </h5>

                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr className=" ">
                        <th scope="">User Name:</th>
                        <td scope="w-100">
                          <Input className="select2-selection" />
                        </td>
                      </tr>

                      <tr className=" ">
                        <th scope="col">User Name:</th>
                        <td scope="col w-100">Magento Developer</td>
                      </tr>

                      <tr className=" ">
                        <th scope="col">User Name:</th>
                        <td scope="col w-100">Magento Developer</td>
                      </tr>

                      <tr className=" ">
                        <th scope="col">User Name:</th>
                        <td scope="col w-100">Magento Developer</td>
                      </tr>

                      <tr className=" ">
                        <th scope="col">User Name:</th>
                        <td scope="col w-100">Magento Developer</td>
                      </tr>

                      <tr className=" ">
                        <th scope="row">
                          {" "}
                          <button className="btn btn-soft-primary w-100">
                            Apply Now
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody className=" ">
                <h5 className="fw-semibold text-center">Create New Location</h5>

                <div className="">
                  <table className="table ">
                    <tbody>
                      <tr className=" ">
                        <th scope="">User Name:</th>
                        <td scope="w-100">
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </td>
                      </tr>

                      <tr className=" ">
                        <th scope="">User Name:</th>
                        <td scope="w-100">
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </td>
                      </tr>

                      <tr className=" ">
                        <th scope="">User Name:</th>
                        <td scope="w-100">
                          <Input className="select2-selection" />
                        </td>
                      </tr>

                      <tr className=" ">
                        <th scope="row">
                          {" "}
                          <button className="btn btn-soft-primary w-100">
                            Apply Now
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Modal
          isOpen={modal}
          toggle={() => {
            setModal()
          }}
          id="approvelocation"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModal()}
              id="applyJobsLabel"
              className="modal-header"
            >
              Approve Location Request
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={validation.handleSubmit} autoComplete="off">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="fullnameInput">Sub Area</Label>
                      <Input
                        type="text"
                        id="fullnameInput"
                        name="fullnameInput"
                        placeholder="Enter your name"
                        value={validation.values.fullnameInput}
                        onChange={validation.handleChange}
                      />
                      {validation.touched.fullnameInput &&
                      validation.errors.fullnameInput ? (
                        <FormFeedback type="invalid" className="d-block">
                          {validation.errors.fullnameInput}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="emailInput">Area : </Label>
                      <Label htmlFor="emailInput"> Ambegaon BK</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="emailInput">City : </Label>
                      <Label htmlFor="emailInput"> Pune</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="emailInput">Request Time : </Label>
                      <Label htmlFor="emailInput"> 24/05/2024 7:8 AM</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label htmlFor="emailInput">Sent By : </Label>
                      <Label htmlFor="emailInput"> Anuja Power</Label>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="text-end">
                      <button type="submit" className="btn btn-success me-1">
                        Submit
                        <i className="bx bx-send align-middle"></i>
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={modal1}
          toggle1={() => {
            setModal1()
          }}
          id="craetenewlocation"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModal1()}
              id="applyJobsLabel"
              className="modal-header"
            >
              Create New Location
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={validation.handleSubmit} autoComplete="off">
                <Form onSubmit={validation.handleSubmit} autoComplete="off">
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="fullnameInput">Sub Area</Label>
                        <Input
                          type="text"
                          id="fullnameInput"
                          name="fullnameInput"
                          placeholder="Enter your name"
                          value={validation.values.fullnameInput}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.fullnameInput &&
                        validation.errors.fullnameInput ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.fullnameInput}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="emailInput">Area : </Label>
                        <Label htmlFor="emailInput"> Ambegaon BK</Label>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="emailInput">City : </Label>
                        <Label htmlFor="emailInput"> Pune</Label>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="emailInput">Request Time : </Label>
                        <Label htmlFor="emailInput"> 24/05/2024 7:8 AM</Label>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="emailInput">Sent By : </Label>
                        <Label htmlFor="emailInput"> Anuja Power</Label>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success me-1">
                          Submit
                          <i className="bx bx-send align-middle"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Form>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
LocationApprovalReq.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default LocationApprovalReq
