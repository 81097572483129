import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import ProductList from "pages/Mylayout/ProductList"

const Navbar = props => {
  // const [dashboard, setdashboard] = useState(false)
  // const [ui, setui] = useState(false)
  // const [app, setapp] = useState(false)
  // const [email, setemail] = useState(false)
  // const [ecommerce, setecommerce] = useState(false)
  // const [crypto, setcrypto] = useState(false)
  // const [project, setproject] = useState(false)
  // const [task, settask] = useState(false)
  // const [contact, setcontact] = useState(false)
  // const [blog, setBlog] = useState(false)
  // const [job, setJob] = useState(false)
  // const [candidate, setCandidate] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setcomponent(!component)
                    }}
                  >
                    <i className="bx bx-collection me-2"></i>
                    {props.t("System")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    <div className="dropdown">
                      <Link to="/userlist" className="dropdown-item">
                        {props.t("Users")}
                      </Link>
                      {/* <div
                        className={classname("dropdown-menu", { show: form })}
                      >
                        <Link to="/productlist" className="dropdown-item">
                          {props.t("")}
                        </Link>
                        <Link to="/createoredituser" className="dropdown-item">
                          {props.t("Create User")}
                        </Link>
                      </div> */}
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          settable(!table)
                        }}
                      >
                        {props.t("System Confuguration")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: table })}
                      >
                        <Link to="/stickyheaders" className="dropdown-item">
                          {props.t("Sticky Headers")}
                        </Link>
                        <Link
                          to="/woconfirmationplay"
                          className="dropdown-item"
                        >
                          {props.t("Confirmation Play for W/O")}
                        </Link>
                        <Link to="/followupreason" className="dropdown-item">
                          {props.t("Reason Templates")}
                        </Link>
                        <Link
                          to="/breaktimeconfigurtaion"
                          className="dropdown-item"
                        >
                          {props.t("User Break Time")}
                        </Link>
                        <Link
                          to="/systemconfigurationparameters"
                          className="dropdown-item"
                        >
                          {props.t("System Configuration Parameters")}
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setchart(!chart)
                        }}
                      >
                        {props.t("Schedular Assignement ")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: chart })}
                      >
                        <Link
                          to="/schedulerassignment"
                          className="dropdown-item"
                        >
                          {" "}
                          {props.t("Location Wise")}
                        </Link>
                        {/* <Link
                          to="/schedulerassignment"
                          className="dropdown-item"
                        >
                          {" "}
                          {props.t("Product Wise")}
                        </Link> */}
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          seticon(!icon)
                        }}
                      >
                        {props.t("Organization Setting")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: icon })}
                      >
                        <Link
                          to="/importcustomerdata"
                          className="dropdown-item"
                        >
                          {props.t("Import Customer's Old Data")}
                        </Link>
                        <Link
                          to="/uploadorchangeorgdata"
                          className="dropdown-item"
                        >
                          {props.t("Upload Org Details (Name & Logo)")}
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setmap(!map)
                        }}
                      >
                        {props.t("Feedback Questions")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: map })}
                      >
                        <Link to="/feedbackquestion" className="dropdown-item">
                          {props.t("Edit Quetions")}{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setextra(!extra)
                    }}
                  >
                    <i className="bx bx-file me-2"></i>
                    {props.t("Operations")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: extra })}>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setinvoice(!invoice)
                        }}
                      >
                        {props.t("User Device Auth ")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: invoice,
                        })}
                      >
                        <Link
                          to="/userdeviceauthoriztionlist"
                          className="dropdown-item"
                        >
                          {props.t("Authorized Devices")}
                        </Link>
                        <Link to="/authpendingreq" className="dropdown-item">
                          {props.t("Pending Auth Request")}
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setauth(!auth)
                        }}
                      >
                        {props.t("Location Approval Request")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: auth })}
                      >
                        <Link
                          to="/locationapprovalreq"
                          className="dropdown-item"
                        >
                          {props.t("Add/Remove Location")}
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        className="dropdown-item dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setutility(!utility)
                        }}
                      >
                        {props.t("Block Customer ")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: utility,
                        })}
                      >
                        {/* <Link to="/blockedcustomer" className="dropdown-item">
                          {props.t("Blocked Customer List")}
                        </Link> */}
                        <Link
                          to="/blockedcustomerlist"
                          className="dropdown-item"
                        >
                          {props.t("Block/Unblock Customer")}
                        </Link>
                      </div>
                    </div>
                    <Link
                      to="/manualchallanworkorders"
                      className="dropdown-item"
                    >
                      {props.t("Manual Challan Approval Requests")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setextra(!extra)
                    }}
                  >
                    <i className="bx bx-file me-2"></i>
                    {props.t("Reports")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: extra })}>
                    <Link
                      to="/presentyworksummeryreport"
                      className="dropdown-item"
                    >
                      {props.t("Presenty & Work Summery Report")}
                    </Link>
                    <Link
                      to="/datewiseproductstatisticsreport"
                      className="dropdown-item"
                    >
                      {props.t("Day Wise Product Statistics Report")}
                    </Link>
                    <Link
                      to="/dailyworkinghoursreport"
                      className="dropdown-item"
                    >
                      {props.t("Daily Working Hour Report")}
                    </Link>
                    <Link
                      to="/complaintoverheadreport"
                      className="dropdown-item"
                    >
                      {props.t("Complaint Overhead")}
                    </Link>
                    <Link
                      to="/complaintoverheadreport"
                      className="dropdown-item"
                    >
                      {props.t("Complaint Report Product Research")}
                    </Link>
                    <Link to="/lostreport" className="dropdown-item">
                      {props.t("Lost Report")}
                    </Link>
                    <Link
                      to="/productwisesalesreport"
                      className="dropdown-item"
                    >
                      {props.t("Product Wise Sales Report")}
                    </Link>
                    <Link
                      to="/productwisecomplaintreport"
                      className="dropdown-item"
                    >
                      {props.t("Product Wise Complaint Report")}
                    </Link>
                    <Link
                      to="/workorderpostponeReport"
                      className="dropdown-item"
                    >
                      {props.t("Work Order Postpone Report")}
                    </Link>
                    <Link to="/feedbackreport" className="dropdown-item">
                      {props.t("Customer Feedback Report")}
                    </Link>
                    <Link to="/workorderfeedback" className="dropdown-item">
                      {props.t("Work Order Feedback Report")}
                    </Link>
                    <Link to="/enquirycalllogreport" className="dropdown-item">
                      {props.t("Schedular Call Log Report")}
                    </Link>
                    <Link
                      to="/reportmanualchallanworkorders"
                      className="dropdown-item"
                    >
                      {props.t("Manual Challan Request Report")}
                    </Link>
                    <Link to="/callogreport" className="dropdown-item">
                      {props.t("Call Log")}
                    </Link>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
