// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

const UserList = () => {
  const columns = useMemo(
    () => [
      {
        header: "Sr No",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Weekly Off",
        accessorKey: "weekoff",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Designation",
        accessorKey: "designation",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Department",
        accessorKey: "department",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Team Leader",
        accessorKey: "teamleader",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Manager",
        accessorKey: "manager",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="/job-details"
                  className="btn btn-sm btn-soft-primary"
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <Link
                  to="/createoredituser"
                  className="btn btn-sm btn-soft-info"
                >
                  <i className="mdi mdi-pencil-outline" />
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      srno: "1",
      name: "Anuja Power",
      mobile: 8768787887,
      weekoff: "Tuesday",
      designation: "Telecaller",
      department: "Sales",
      teamleader: "Manish Gupta",
      manager: "Piyush Wani",
      status: "Active",
      // actions: "$357,650",
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="User List" />
        <Card>
          <CardBody className="border-bottom">
            <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/createoredituser"
                  // onClick={() => setModal(true)}
                  className="btn btn-primary me-1"
                >
                  Add New User
                </Link>
              </div>
            </div>
            <TableContainer
              columns={columns}
              data={data || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search..."
              isCustomPageSize={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
UserList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default UserList
