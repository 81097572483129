// src/components/filter.
import React, { useMemo, useRef, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"
import Select from "react-select"
import { Formik, useFormik } from "formik"
import * as Yup from "yup"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const StickyHeaders = props => {
  //meta title

  document.title = "Dashboard | Skote - React Admin & Dashboard Template"
  const [customchkPrimary, setcustomchkPrimary] = useState(true)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Sticky Headers " />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Form Grid Layout</CardTitle> */}

                  <Form onSubmit={Formik.handleSubmit}>
                    <Row>
                      <Col lg="2">
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Select All Headers
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Follow Up
                          </label>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            In Guarantee
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Today's W/O
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Old Customers
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            New Enquiry
                          </label>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            W/O Placed
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Today W/O
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Recalling
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Confirmation
                          </label>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={customchkPrimary}
                            onChange={() => {
                              setcustomchkPrimary(!customchkPrimary)
                            }}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                          >
                            Feedback
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <button type="submit" className="btn btn-primary w-md">
                      Submit
                    </button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default StickyHeaders
