import React from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
//import images
// import wechat from "../../../assets/images/companies/wechat.svg"
import Select from "react-select"
const FeedbackQuestion = () => {
  //meta title
  document.title = "Form Repeater | Skote - React Admin & Dashboard Template"

  const [rows1, setrows1] = useState([{ id: 1, phone: "" }])

  const handleAddRowNested = () => {
    const newRow = { id: Math.floor(Math.random() * (30 - 20)) + 20, phone: "" }
    setrows1([...rows1, newRow])
  }

  const handleRemoveRow = id => {
    const updatedRows = rows1.filter(row => row.id !== id)
    setrows1(updatedRows)
  }

  // Function to handle changes in input fields
  const handleInputChangeNested = (id, name, value) => {
    const updatedRows = rows1.map(row => {
      if (row.id === id) {
        return { row, [name]: value }
      }
      return row
    })
    setrows1(updatedRows)
  }

  const validation = useFormik({
    initialValues: {
      fullnameInput: "",
      emailInput: "",
      phoneNumberInput: "",
      uploadResume: "",
      messageInput: "",
    },
    validationSchema: Yup.object({
      fullnameInput: Yup.string().required("Please Enter Your Name"),
      emailInput: Yup.string().required("Please Enter Your Email"),
      phoneNumberInput: Yup.string().required("Please Enter Your Number"),
      uploadResume: Yup.string().required("Add Your Resume"),
      messageInput: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: values => {
      setModal()
      validation.resetForm()
    },
  })
  const [modal, setModal] = useState(false)
  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]
  return (
    <React.Fragment>
      <Row md={2}>
        <Col md={{ span: 1, offset: 3 }} className="page-content">
          <Card>
            <CardBody className="border-bottom">
              <div className="d-flex">
                <div className="flex-grow-1 ms-3">
                  <h5 className="fw-semibold">Feedback Questions</h5>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <div className="flex-shrink-0">
                    <Link
                      to="#craetenewlocation"
                      onClick={() => setModal(true)}
                      className="btn btn-primary me-1"
                    >
                      Add Question
                    </Link>
                  </div>
                </div>
              </div>
            </CardBody>
            <CardBody className="ms-3">
              <h5 className="fw-semibold mb-3">Overall Service Questions</h5>

              <div className="d-flex align-items-center justify-content-between">
                <label className="text-muted mb-3">
                  कंपनीची एकूण सेवा कशी होती ?
                </label>
                <div className="flex-shrink-0">
                  <ul className="list-unstyled hstack gap-1 mb-0">
                    <li>
                      <Link
                        to="#craetenewlocation"
                        onClick={() => setModal(true)}
                        className="btn btn-sm btn-soft-info"
                      >
                        <i className="mdi mdi-pencil-outline" />
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="#"
                        className="btn btn-sm btn-soft-danger"
                        onClick={() => {
                          const jobData = cellProps.row.original
                          onClickDelete(jobData)
                        }}
                      >
                        <i className="mdi mdi-delete-outline" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="customRadioInline1"
                    className="form-check-input"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline1"
                  >
                    सर्वोत्तम
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    उत्तम
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    चांगली
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    समाधानी नाही
                  </label>
                </div>
                &nbsp;
                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Descriptive Answer
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <Label className="text-muted mb-3">
                  कोणत्याही सुधारणांची गरज आहे का ?
                </Label>

                <div className="flex-shrink-0">
                  <ul className="list-unstyled hstack gap-1 mb-0">
                    <li>
                      <Link
                        to="#craetenewlocation"
                        onClick={() => setModal(true)}
                        className="btn btn-sm btn-soft-info"
                      >
                        <i className="mdi mdi-pencil-outline" />
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="#"
                        className="btn btn-sm btn-soft-danger"
                        onClick={() => {
                          const jobData = cellProps.row.original
                        }}
                      >
                        <i className="mdi mdi-delete-outline" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <Label className="form-check-label" htmlFor="defaultCheck1">
                      टेलिकॉलर कम्युनिकेशन
                    </Label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      वर्क ऑर्डरची अंमलबजावणी
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      फील्ड क्झिक्युटिव्ह वर्तन
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Descriptive Answer
                  </label>
                </div>
              </div>
            </CardBody>
            <CardBody className="ms-3">
              <h5 className="fw-semibold mb-3">Filed Executive Questions</h5>

              <div className="d-flex align-items-center justify-content-between">
                <Label className="text-muted mb-3">
                  फील्ड एक्झिक्युटिव्ह योग्य गणवेशात होता का ?
                </Label>

                <div className="flex-shrink-0">
                  <ul className="list-unstyled hstack gap-1 mb-0">
                    <li>
                      <Link
                        to="#craetenewlocation"
                        onClick={() => setModal(true)}
                        className="btn btn-sm btn-soft-info"
                      >
                        <i className="mdi mdi-pencil-outline" />
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="#"
                        className="btn btn-sm btn-soft-danger"
                        onClick={() => {
                          const jobData = cellProps.row.original
                          onClickDelete(jobData)
                        }}
                      >
                        <i className="mdi mdi-delete-outline" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    id="customRadioInline1"
                    className="form-check-input"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline1"
                  >
                    होय
                  </label>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <Input
                    type="radio"
                    className="form-check-input"
                    id="customRadioInline2"
                    name="customRadioInline1 gender"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customRadioInline2"
                  >
                    नाही
                  </label>
                </div>
                &nbsp; &nbsp;
                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Descriptive Answer
                  </label>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <Label className="text-muted mb-3">
                  फील्ड एक्झिक्युटिव्हचे वर्तन कसे होते ?
                </Label>
                <div className="flex-shrink-0">
                  <ul className="list-unstyled hstack gap-1 mb-0">
                    <li>
                      <Link
                        to="#craetenewlocation"
                        onClick={() => setModal(true)}
                        className="btn btn-sm btn-soft-info"
                      >
                        <i className="mdi mdi-pencil-outline" />
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="#"
                        className="btn btn-sm btn-soft-danger"
                        onClick={() => {
                          const jobData = cellProps.row.original
                          onClickDelete(jobData)
                        }}
                      >
                        <i className="mdi mdi-delete-outline" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-4">
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      चांगले
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      सहकार्य करण्यास तयार
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check form-check-inline">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      सुधारणांची गरज आहे
                    </label>
                  </div>
                </div>
                &nbsp;
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    Descriptive Answer
                  </label>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal()
        }}
        id="approvelocation"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Add or Edit Question
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={validation.handleSubmit} autoComplete="off">
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <div className="mb-3">
                      <Label>Overall Service</Label>
                      <Select
                        value={selectedGroup}
                        onChange={() => {
                          handleSelectGroup()
                        }}
                        options={optionGroup}
                        className="select2-selection"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3">
                    <Label htmlFor="fullnameInput">Question</Label>
                    <Input
                      type="text"
                      id="fullnameInput"
                      name="fullnameInput"
                      placeholder=""
                      value={validation.values.fullnameInput}
                      onChange={validation.handleChange}
                    />
                    {validation.touched.fullnameInput &&
                    validation.errors.fullnameInput ? (
                      <FormFeedback type="invalid" className="d-block">
                        {validation.errors.fullnameInput}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={12}>
                  <Label htmlFor="fullnameInput">Answer Mathodology</Label>

                  <div className="mb-4">
                    <div className="form-check form-check-inline">
                      <Input
                        type="radio"
                        id="customRadioInlinepop"
                        className="form-check-input"
                        name="customRadioInlinepop sel"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customRadioInlinepop"
                      >
                        Single Select
                      </label>
                    </div>
                    &nbsp;
                    <div className="form-check form-check-inline">
                      <Input
                        type="radio"
                        className="form-check-input"
                        id="customRadioInlinepop2"
                        name="customRadioInlinepop sel"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customRadioInlinepop2"
                      >
                        Multi Select
                      </label>
                    </div>
                    &nbsp;
                    <div className="form-check form-check-inline">
                      <Input
                        type="radio"
                        className="form-check-input"
                        id="customRadioInlineepop3"
                        name="customRadioInlinepop sel"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customRadioInlinepop3"
                      >
                        None
                      </label>
                    </div>
                    <div className="form-check mb-3 mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="defaultCheck1"
                      >
                        Descriptive Answer
                      </label>
                    </div>
                  </div>
                </Col>
                <div className="inner-repeater mb-4">
                  <Label>Answer :</Label>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {(rows1 || []).map((formRow, key) => (
                        <tr key={key}>
                          <td>
                            <Row className="mb-2">
                              <Col md="10" xs="8">
                                <Input
                                  type="text"
                                  name={`phone${formRow.id}`}
                                  value={formRow.phone}
                                  className="inner form-control"
                                  placeholder="Enter your Answer"
                                  onChange={e =>
                                    handleInputChangeNested(
                                      formRow.id,
                                      "phone",
                                      e.target.value
                                    )
                                  }
                                />
                              </Col>
                              <Col md="2" xs="4">
                                <div className="d-grid">
                                  <Button
                                    color="primary"
                                    className="btn-block inner"
                                    id="unknown-btn"
                                    style={{ width: "100%" }}
                                    onClick={() => handleRemoveRow(formRow.id)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Button
                    onClick={() => {
                      handleAddRowNested()
                    }}
                    color="success"
                    className="mt-1"
                  >
                    Add
                  </Button>
                </div>
                <Col lg={12}>
                  <div className="text-start">
                    <button type="submit" className="btn btn-success me-1">
                      Submit
                      <i className="bx bx-send align-middle"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default FeedbackQuestion
