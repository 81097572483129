// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, CardTitle, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"

const ProductList = () => {
  const columns = useMemo(
    () => [
      {
        header: "Sr No",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Category",
        accessorKey: "category",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Subcategory",
        accessorKey: "subcategory",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Product Name",
        accessorKey: "proname",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Price Rs.",
        accessorKey: "price",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Min Price Rs.",
        accessorKey: "minprice",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Price Play",
        accessorKey: "priceplay",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Guarantee",
        accessorKey: "guarantee",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Executions",
        accessorKey: "executions",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Interval Days",
        accessorKey: "interval",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Telecallers",
        accessorKey: "telecallers",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Creation Date",
        accessorKey: "cdate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="/job-details"
                  className="btn btn-sm btn-soft-primary"
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.id}`}
              >
                View
              </UncontrolledTooltip>
              <li>
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-sm btn-soft-info"
                >
                  <i className="mdi mdi-pencil-outline" />
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleJobClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li> */}

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const data = [
    {
      srno: "1",
      category: "Category",
      subcategory: "Sub-ABCD",
      proname: "Product Abcd",
      price: "2500",
      minprice: "2300",
      priceplay: "100",
      guarantee: "1 Year",
      executions: "2",
      interval: "30",
      telecallers: "3",
      cdate: "2010/11/14",
      // actions: "$",
    },
  ]

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Tables" breadcrumbItem="Product List" />
        <Card>
          <CardBody className="border-bottom">
            <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/CreateorEditProduct"
                  // onClick={() => setModal(true)}
                  className="btn btn-primary me-1"
                >
                  Add New Product
                </Link>
              </div>
            </div>

            <CardTitle className="mb-4">Active Product</CardTitle>

            <TableContainer
              columns={columns}
              data={data || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search..."
              isCustomPageSize={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody className="border-bottom">
            {/* <div className="d-flex align-items-center justify-content-end">
              <div className="flex-shrink-0">
                <Link
                  to="/CreateorEditProduct"
                  className="btn btn-primary me-1"
                >
                  Add New Product
                </Link>
              </div>
            </div> */}

            <CardTitle className="mb-4">Delivered Product</CardTitle>

            <TableContainer
              columns={columns}
              data={data || []}
              isGlobalFilter={true}
              isPagination={true}
              SearchPlaceholder="Search..."
              isCustomPageSize={true}
              tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
              theadClass="table-light"
              paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
              pagination="pagination"
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
ProductList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ProductList
