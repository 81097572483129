// src/components/filter.
import React, { useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
//usestate
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

const Breaktimeconfigurtaion = () => {
  // validation
  const validation = useFormik({
    initialValues: {
      fullnameInput: "",
      emailInput: "",
      phoneNumberInput: "",
      uploadResume: "",
      messageInput: "",
    },
    validationSchema: Yup.object({
      fullnameInput: Yup.string().required("Please Enter Your Name"),
      emailInput: Yup.string().required("Please Enter Your Email"),
      phoneNumberInput: Yup.string().required("Please Enter Your Number"),
      uploadResume: Yup.string().required("Add Your Resume"),
      messageInput: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: values => {
      setModal()
      validation.resetForm()
    },
  })
  const columns = useMemo(
    () => [
      {
        header: "Sr No",
        accessorKey: "srno",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Break",
        accessorKey: "break",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Time",
        accessorKey: "time",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Details",
        accessorKey: "detils",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Alloted For",
        accessorKey: "alloted",
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="/job-details"
                  className="btn btn-sm btn-soft-primary"
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </Link>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original.id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    handleJobClick(jobData)
                  }}
                  id={`edittooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )
  const [textareabadge, settextareabadge] = useState(0)
  function textareachange(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  const data = [
    {
      srno: "1",
      cname: "Lunch Time",
      mobile: "45 Mins",
      blockreason: "Lunch Break",
      sentby: "Telecaller",

      actions: 28,
    },
  ]
  const flatpickrRef = useRef(null)

  const showValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open()
    }
  }

  const closeValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.close()
    }
  }
  const [modal1, setModal1] = useState(false)
  const toggle1 = () => {
    if (modal1) {
      setModal1(false)
      // setJob(null)
    } else {
      setModal1(true)
    }
  }

  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  //usestate

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="page-content">
          <Breadcrumbs
            title="Tables"
            breadcrumbItem="Break Time Configuration"
          />
          <div className="d-flex align-items-center justify-content-end">
            <div className="flex-shrink-0">
              <Link
                to="#craetenewlocation"
                onClick={() => setModal1(true)}
                className="btn btn-primary me-1"
              >
                Add Break
              </Link>
            </div>
          </div>

          <TableContainer
            columns={columns}
            data={data || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="27 records..."
            pagination="pagination"
            paginationWrapper="dataTables_paginate paging_simple_numbers"
            tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
          />
        </div>

        {/* <Row>
          <Col md={9}>
            <Col md={{ span: 1, offset: 4 }}>
              <Card>
                <CardBody className="">
                  <h5 className="fw-semibold text-center">
                    Approve Location Request
                  </h5>

                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr className=" ">
                          <th scope="">User Name:</th>
                          <td scope="w-100">
                            <Input className="select2-selection" />
                          </td>
                        </tr>

                        <tr className=" ">
                          <th scope="col">User Name:</th>
                          <td scope="col w-100">Magento Developer</td>
                        </tr>

                        <tr className=" ">
                          <th scope="col">User Name:</th>
                          <td scope="col w-100">Magento Developer</td>
                        </tr>

                        <tr className=" ">
                          <th scope="col">User Name:</th>
                          <td scope="col w-100">Magento Developer</td>
                        </tr>

                        <tr className=" ">
                          <th scope="col">User Name:</th>
                          <td scope="col w-100">Magento Developer</td>
                        </tr>

                        <tr className=" ">
                          <th scope="row">
                            {" "}
                            <button className="btn btn-soft-primary w-100">
                              Apply Now
                            </button>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className=""></div>
                </CardBody>
              </Card>
            </Col>
          </Col>
        </Row> */}
        <Modal
          isOpen={modal1}
          toggle1={() => {
            setModal1()
          }}
          id="craetenewlocation"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModal1()}
              id="applyJobsLabel"
              className="modal-header"
            >
              Add Break
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={validation.handleSubmit} autoComplete="off">
                <Form onSubmit={validation.handleSubmit} autoComplete="off">
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="fullnameInput"> Break</Label>
                        <Input
                          type="text"
                          id="fullnameInput"
                          name="fullnameInput"
                          placeholder="Enter your name"
                          value={validation.values.fullnameInput}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.fullnameInput &&
                        validation.errors.fullnameInput ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.fullnameInput}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label>Break Time</Label>
                        <InputGroup>
                          <Flatpickr
                            className="form-control d-block"
                            placeholder="Select time"
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "H:i",
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <div className="mb-3">
                          <Label>Alloted For</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <div className="mb-3">
                          <Label>Details</Label>

                          <Input
                            type="textarea"
                            id="textarea"
                            onChange={e => {
                              textareachange(e)
                            }}
                            maxLength="225"
                            rows="3"
                            placeholder="This textarea has a limit of 225 chars."
                          />
                          {textareabadge ? (
                            <span className="badgecount badge bg-success">
                              {" "}
                              {textcount} / 225{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success me-1">
                          Submit
                          <i className="bx bx-send align-middle"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Form>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}
Breaktimeconfigurtaion.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Breaktimeconfigurtaion
