// src/components/filter.
import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"

const Blockedcustomer = () => {
  //usestate

  //meta title
  document.title = "Data Tables | Skote - React Admin & Dashboard Template"

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="page-content">
          <Breadcrumbs title="Tables" breadcrumbItem="Block Custoner" />

          <Row>
            <Col md={9}>
              <Col md={{ span: 1, offset: 4 }}>
                <Card>
                  <CardBody className="">
                    <h5 className="fw-semibold text-center">Block Customer</h5>

                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr className=" ">
                            <th scope="">User Name:</th>
                            <td scope="w-100">
                              <Input className="select2-selection" />
                            </td>
                          </tr>

                          <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr>

                          <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr>

                          <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr>

                          <tr className=" ">
                            <th scope="col">User Name:</th>
                            <td scope="col w-100">Magento Developer</td>
                          </tr>

                          <tr className=" ">
                            <th scope="row">
                              {" "}
                              <button className="btn btn-soft-primary w-100">
                                Apply Now
                              </button>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="">
                      {/* <table className="table d-flex justify-content-center">
                    <tbody>
                      <tr className=" d-flex justify-content-center">
                        <th scope="">User Name:</th>
                        <td scope="">
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </td>
                      </tr>

                      <tr className=" d-flex justify-content-center">
                        <th scope="col">User Name:</th>
                        <td scope="col">Magento Developer</td>
                      </tr>

                      <tr className=" d-flex justify-content-center">
                        <th scope="col">User Name:</th>
                        <td scope="col">Magento Developer</td>
                      </tr>

                      <tr className=" d-flex justify-content-center">
                        <th scope="row">
                          {" "}
                          <button className="btn btn-soft-primary w-100">
                            Apply Now
                          </button>
                        </th>
                      </tr>
                    </tbody>
                  </table> */}
                      {/* <Row className="mb-4 d-flex justify-content-center">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-4  col-form-label"
                    >
                      <b>First name :</b>
                    </Label>
                    <Col sm={8}>
                      <p
                        name="firstname"
                        type="text"
                        className="form-control"
                        id="horizontal-firstname-Input"
                        placeholder="Enter Your"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-4 d-flex justify-content-center">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-4 col-form-label"
                    >
                      <b>User name :</b>
                    </Label>
                    <Col sm={8}>
                      <p className=" col-form-label">Magento Developer</p>
                    </Col>
                  </Row>
                  <Row className="mb-4 d-flex justify-content-center">
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-sm-4 col-form-label"
                    >
                      <b>User name :</b>
                    </Label>
                    <Col sm={8}>
                      <p className="col-form-label">Magento Developer</p>
                    </Col>
                  </Row> */}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Blockedcustomer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Blockedcustomer
