// src/components/filter.
import React, { useMemo, useRef, useState } from "react"

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  FormFeedback,
} from "reactstrap"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const CreateorEdituser = props => {
  //meta title
  document.title = "Form Layouts | Skote - React Admin & Dashboard Template"

  //Grid form
  const formik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      city: "",
      state: "",
      zip: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      zip: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  //Horizontal form
  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  //auto sizeing form
  const autoformik = useFormik({
    initialValues: {
      name: "",
      username: "",
      state: "",
      check: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      username: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  //Inline forms
  const inlineformik = useFormik({
    initialValues: {
      username: "",
      select: "",
      check: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("This field is required"),
      select: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  //Floating labels forms
  const floatingformik = useFormik({
    initialValues: {
      name: "",
      email: "",
      select: "",
      check: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      select: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })
  //meta title

  document.title = "Dashboard | Skote - React Admin & Dashboard Template"
  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Position",
        accessorKey: "position",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Office",
        accessorKey: "office",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Age",
        accessorKey: "age",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Start date",
        accessorKey: "startDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Salary",
        accessorKey: "salary",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  )

  const data = [
    {
      name: "Jennifer Chang",
      position: "Regional Director",
      age: 28,
      office: "Singapore",
      startDate: "2010/11/14",
      salary: "$357,650",
    },
    {
      name: "Gavin Joyce",
      position: "Developer",
      age: 42,
      office: "Edinburgh",
      startDate: "2010/12/22",
      salary: "$92,575",
    },
    {
      name: "Angelica Ramos",
      position: "Chief Executive Officer (CEO)",
      age: 47,
      office: "London",
      startDate: "2009/10/09",
      salary: "$1,200,000",
    },
    {
      name: "Doris Wilder",
      position: "Sales Assistant",
      age: 23,
      office: "Sidney",
      startDate: "2010/09/20",
      salary: "$85,600",
    },
    {
      name: "Caesar Vance",
      position: "Pre-Sales Support",
      age: 21,
      office: "New York",
      startDate: "2011/12/12",
      salary: "$106,450",
    },
    {
      name: "Yuri Berry",
      position: "Chief Marketing Officer (CMO)",
      age: 40,
      office: "New York",
      startDate: "2009/06/25",
      salary: "$675,000",
    },
    {
      name: "Jenette Caldwell",
      position: "Development Lead",
      age: 30,
      office: "New York",
      startDate: "2011/09/03",
      salary: "$345,000",
    },
    {
      name: "Dai Rios",
      position: "Personnel Lead",
      age: 35,
      office: "Edinburgh",
      startDate: "2012/09/26",
      salary: "$217,500",
    },
    {
      name: "Bradley Greer",
      position: "Software Engineer",
      age: 41,
      office: "London",
      startDate: "2012/10/13",
      salary: "$132,000",
    },
    {
      name: "Gloria Little",
      position: "Systems Administrator",
      age: 59,
      office: "New York",
      startDate: "2009/04/10",
      salary: "$237,500",
    },
    {
      name: "Paul Byrd",
      position: "Chief Financial Officer (CFO)",
      age: 64,
      office: "New York",
      startDate: "2010/06/09",
      salary: "$725,000",
    },
    {
      name: "Michael Silva",
      position: "Marketing Designer",
      age: 66,
      office: "London",
      startDate: "2012/11/27",
      salary: "$198,500",
    },
    {
      name: "Tatyana Fitzpatrick",
      position: "Regional Director",
      age: 19,
      office: "London",
      startDate: "2010/03/17",
      salary: "$385,750",
    },
    {
      name: "Haley Kennedy",
      position: "Senior Marketing Designer",
      age: 43,
      office: "London",
      startDate: "2012/12/18",
      salary: "$313,500",
    },
    {
      name: "Charde Marshall",
      position: "SRegional Director",
      age: 36,
      office: "San Francisco",
      startDate: "2008/10/16",
      salary: "$470,600",
    },
    {
      name: "Quinn Flynn",
      position: "Support Lead",
      age: 22,
      office: "Edinburgh",
      startDate: "2013/03/03",
      salary: "$342,000",
    },
    {
      name: "Jena Gaines",
      position: "Office Manager",
      age: 30,
      office: "London",
      startDate: "2008/12/19",
      salary: "$90,560",
    },
    {
      name: "Sonya Frost",
      position: "Software Engineer",
      age: 23,
      office: "Edinburgh",
      startDate: "2008/12/13",
      salary: "$103,600",
    },
    {
      name: "Colleen Hurst",
      position: "Javascript Developer",
      age: 39,
      office: "San Francisco",
      startDate: "2009/09/15",
      salary: "$205,500",
    },
    {
      name: "Rhona Davidson",
      position: "Integration Specialist",
      age: 55,
      office: "Tokyo",
      startDate: "2010/10/14",
      salary: "$327,900",
    },
    {
      name: "Herrod Chandler",
      position: "Sales Assistant",
      age: 59,
      office: "San Francisco",
      startDate: "2012/08/06",
      salary: "$137,500",
    },
    {
      name: "Brielle Williamson",
      position: "Integration Specialist",
      age: 62,
      office: "New York",
      startDate: "2012/12/02",
      salary: "$372,000",
    },
    {
      name: "Airi Satou",
      position: "Accountant",
      age: 33,
      office: "Tokyo",
      startDate: "2008/11/28",
      salary: "$162,700",
    },
    {
      name: "Cedric Kelly",
      position: "Senior Javascript Developer",
      age: 22,
      office: "Edinburgh",
      startDate: "2012/03/29",
      salary: "$433,060",
    },
    {
      name: "Ashton Cox",
      position: "Junior Technical Author",
      age: 66,
      office: "San Francisco",
      startDate: "2009/01/12",
      salary: "$86,000",
    },
    {
      name: "Garrett Winters",
      position: "Accountant",
      age: 63,
      office: "Tokyo",
      startDate: "2011/07/25",
      salary: "$170,750",
    },
    {
      name: "Tiger Nixon",
      position: "System Architect",
      age: 61,
      office: "Edinburgh",
      startDate: "2011/04/25",
      salary: "$320,800",
    },
  ]

  //usestate
  const [selectedGroup, setselectedGroup] = useState(null)

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" },
      ],
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" },
      ],
    },
  ]

  const [textareabadge, settextareabadge] = useState(0)
  function textareachange(event) {
    const count = event.target.value.length
    if (count > 0) {
      settextareabadge(true)
    } else {
      settextareabadge(false)
    }
    settextcount(event.target.value.length)
  }

  const flatpickrRef = useRef(null)

  const showValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.open()
    }
  }

  const closeValue = () => {
    if (flatpickrRef.current) {
      flatpickrRef.current.flatpickr.close()
    }
  }

  document.title = "Data Tables | Skote - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Create or Edit User" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Form Grid Layout</CardTitle> */}

                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg="2">
                        <div className="mb-3">
                          <Label>Initials</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            First Name
                          </Label>
                          <Input
                            type="text"
                            name="fname"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your First Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Middle Name
                          </Label>
                          <Input
                            type="text"
                            name="mname"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your MIddle Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Last Name
                          </Label>
                          <Input
                            type="text"
                            name="lname"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Last Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Mobile No.
                          </Label>
                          <Input
                            type="text"
                            name="mobile"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Mobile Number"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Whatsapp No.
                          </Label>
                          <Input
                            type="text"
                            name="whatsapp"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Whatsapp Number"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Alternate No.
                          </Label>
                          <Input
                            type="text"
                            name="alternateno"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your Alternate Number"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label>Address Line 1</Label>

                          <Input
                            type="textarea"
                            id="textarea"
                            onChange={e => {
                              textareachange(e)
                            }}
                            maxLength="225"
                            rows="3"
                            placeholder="This textarea has a limit of 225 chars."
                          />
                          {textareabadge ? (
                            <span className="badgecount badge bg-success">
                              {" "}
                              {textcount} / 225{" "}
                            </span>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="mb-3">
                          <Label>Address Line 2</Label>

                          <Input
                            type="textarea"
                            id="textarea"
                            onChange={e => {
                              textareachange(e)
                            }}
                            maxLength="225"
                            rows="3"
                            placeholder="This textarea has a limit of 225 chars."
                          />
                          {textareabadge ? (
                            <span className="badgecount badge bg-success">
                              {" "}
                              {textcount} / 225{" "}
                            </span>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">City</Label>
                          <Input
                            type="text"
                            name="city"
                            className="form-control"
                            id="formrow-InputCity"
                            placeholder="Enter Your Living City"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.city && formik.errors.city
                                ? true
                                : false
                            }
                          />
                          {formik.errors.city && formik.touched.city ? (
                            <FormFeedback type="invalid">
                              {formik.errors.city}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">State</Label>
                          <select
                            name="state"
                            id="formrow-InputState"
                            className="form-control"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option>Choose...</option>
                            <option>...</option>
                          </select>
                          {formik.errors.state && formik.touched.state ? (
                            <span className="text-danger">
                              {formik.errors.state}
                            </span>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputZip">Pincode</Label>
                          <Input
                            type="text"
                            name="zip"
                            className="form-control"
                            id="formrow-InputZip"
                            placeholder="Enter Your Zip Code"
                            value={formik.values.zip}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.zip && formik.errors.zip
                                ? true
                                : false
                            }
                          />
                          {formik.errors.zip && formik.touched.zip ? (
                            <FormFeedback type="invalid">
                              {formik.errors.zip}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>Birth Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div className="form-group mb-4">
                          <Label>Joining Date</Label>
                          <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label htmlFor="formFile" className="form-label">
                            Photo
                          </Label>
                          <Input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label htmlFor="formFile" className="form-label">
                            Pancard
                          </Label>
                          <Input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label>Photo ID Proof</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label htmlFor="formFile" className="form-label">
                            Photo ID Proof File
                          </Label>
                          <Input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label> Address ID Proof </Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="mb-3">
                          <Label htmlFor="formFile" className="form-label">
                            Address ID Proof File
                          </Label>
                          <Input
                            className="form-control"
                            type="file"
                            id="formFile"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Label>Designation</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Label>Reporting To</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="mb-3">
                          <Label>Weekly Off</Label>
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            className="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            User Name
                          </Label>
                          <Input
                            type="text"
                            name="username"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder="Enter Your User Name"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.firstname &&
                              formik.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {formik.errors.firstname &&
                          formik.touched.firstname ? (
                            <FormFeedback type="invalid">
                              {formik.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            Password
                          </Label>
                          <Input
                            type="password"
                            name="password"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Enter Your Password"
                            autoComplete="off"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.password && formik.errors.password
                                ? true
                                : false
                            }
                          />
                          {formik.errors.password && formik.touched.password ? (
                            <FormFeedback type="invalid">
                              {formik.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">
                            Confirm Password
                          </Label>
                          <Input
                            type="password"
                            name="conpassword"
                            className="form-control"
                            id="formrow-password-Input"
                            placeholder="Enter Your Password"
                            autoComplete="off"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.password && formik.errors.password
                                ? true
                                : false
                            }
                          />
                          {formik.errors.password && formik.touched.password ? (
                            <FormFeedback type="invalid">
                              {formik.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default CreateorEdituser
