import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Link } from "react-router-dom"
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import * as Yup from "yup"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useFormik } from "formik"

const FollowupReason = () => {
  const validation = useFormik({
    initialValues: {
      fullnameInput: "",
      emailInput: "",
      phoneNumberInput: "",
      uploadResume: "",
      messageInput: "",
    },
    validationSchema: Yup.object({
      fullnameInput: Yup.string().required("Please Enter Your Name"),
      emailInput: Yup.string().required("Please Enter Your Email"),
      phoneNumberInput: Yup.string().required("Please Enter Your Number"),
      uploadResume: Yup.string().required("Add Your Resume"),
      messageInput: Yup.string().required("Please Enter Your Message"),
    }),
    onSubmit: values => {
      setModal()
      validation.resetForm()
    },
    onSubmit: values => {
      setModal1()
      validation.resetForm()
    },
  })
  const [modal, setModal] = useState(false)
  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  const [modal1, setModa1l] = useState(false)
  const toggle1 = () => {
    if (modal1) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }

  //usestate

  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string()
        .email()
        .matches(/^(?!.*@[^,]*,)/)
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      // console.log("value", values.password);
    },
  })

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Tasks" breadcrumbItem="Reason Template" />
          {/* Render Breadcrumbs */}
          <Row>
            <Col lg={6}>
              <Card key="">
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title mt-2 mb-3">
                      Follow-up Resaon Template
                    </h4>
                    <div className="flex-shrink-0">
                      <Link
                        to="#reson1"
                        onClick={() => setModal(true)}
                        className="btn btn-primary me-1"
                      >
                        Add Reason
                      </Link>
                    </div>
                  </div>
                  <div className="table-responsive tab-overflow">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                        {/* {item.cards.map((card, index) => ( */}
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Busy, please call tommorrow
                              </Link>
                            </h5>
                          </td>

                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Out of town
                              </Link>
                            </h5>
                          </td>

                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Let me decide
                              </Link>
                            </h5>
                          </td>
                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Test Test
                              </Link>
                            </h5>
                          </td>

                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Reason Template</CardTitle>
                  <Form onSubmit={horizontalformik.handleSubmit}>
                    <Row className="mb-4">
                      <Col sm={8}>
                        <Input
                          type="password"
                          name="password"
                          className="form-control"
                          id="horizontal-password-Input"
                          autoComplete="off"
                          placeholder="Reason"
                          value={horizontalformik.values.password}
                          onChange={horizontalformik.handleChange}
                          onBlur={horizontalformik.handleBlur}
                          invalid={
                            horizontalformik.touched.password &&
                            horizontalformik.errors.password
                              ? true
                              : false
                          }
                        />
                        {horizontalformik.errors.password &&
                        horizontalformik.touched.password ? (
                          <FormFeedback type="invalid">
                            {horizontalformik.errors.password}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row className="justify-content-end">
                      <Col sm={9}>
                        <div>
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col> */}

            <Col lg={6}>
              <Card key="">
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title mt-2 mb-3">
                      Call Not Connected Template
                    </h4>
                    <div className="flex-shrink-0">
                      <Link
                        to="#reson2"
                        onClick={() => setModal(true)}
                        className="btn btn-primary me-1"
                      >
                        Add Reason
                      </Link>
                    </div>
                  </div>
                  <div className="table-responsive tab-overflow">
                    <table className="table table-nowrap align-middle mb-0">
                      <tbody>
                        {/* {item.cards.map((card, index) => ( */}
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Busy, please call tommorrow
                              </Link>
                            </h5>
                          </td>

                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Out of town
                              </Link>
                            </h5>
                          </td>

                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Let me decide
                              </Link>
                            </h5>
                          </td>
                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr key="">
                          <td>
                            <h5 className="text-truncate font-size-14 m-0">
                              <Link to="#" className="text-dark">
                                Test Test
                              </Link>
                            </h5>
                          </td>

                          <td>
                            <div className="text-center">
                              <span
                                className={`badge rounded-pill badge-soft font-size-11`}
                              >
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li>
                                    <Link
                                      to="#reson1"
                                      onClick={() => setModal(true)}
                                      className="btn btn-sm btn-soft-info"
                                    >
                                      <i className="mdi mdi-pencil-outline" />
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="btn btn-sm btn-soft-danger"
                                      //   onClick={() => {
                                      //     const jobData = cellProps.row.original
                                      //     onClickDelete(jobData)
                                      //   }}
                                      //   id={`deletetooltip-${cellProps.row.original.id}`}
                                    >
                                      <i className="mdi mdi-delete-outline" />
                                    </Link>
                                  </li>
                                </ul>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            toggle={() => {
              setModal()
            }}
            id="reson1"
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => setModal()}
                id="applyJobsLabel"
                className="modal-header"
              >
                Reason Template
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={validation.handleSubmit} autoComplete="off">
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="fullnameInput">Reason</Label>
                        <Input
                          type="text"
                          id="fullnameInput"
                          name="fullnameInput"
                          placeholder=""
                          value={validation.values.fullnameInput}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.fullnameInput &&
                        validation.errors.fullnameInput ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.fullnameInput}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success me-1">
                          Submit
                          <i className="bx bx-send align-middle"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </div>
          </Modal>
          <Modal
            isOpen={modal1}
            toggle={() => {
              setModal()
            }}
            id="reson2"
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => setModal()}
                id="applyJobsLabel"
                className="modal-header"
              >
                Reason Template
              </ModalHeader>
              <ModalBody>
                <Form onSubmit={validation.handleSubmit} autoComplete="off">
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="fullnameInput">Reason</Label>
                        <Input
                          type="text"
                          id="fullnameInput"
                          name="fullnameInput"
                          placeholder=""
                          value={validation.values.fullnameInput}
                          onChange={validation.handleChange}
                        />
                        {validation.touched.fullnameInput &&
                        validation.errors.fullnameInput ? (
                          <FormFeedback type="invalid" className="d-block">
                            {validation.errors.fullnameInput}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success me-1">
                          Submit
                          <i className="bx bx-send align-middle"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  )
}

export default FollowupReason
